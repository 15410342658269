var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"transfer-appointment-form","no-close-on-backdrop":"","hide-header-close":"","no-close-on-esc":"","size":"lg","title":"Transferir Citas"}},[_c('validation-observer',{ref:"refFormObserver"},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('h4',[_vm._v("Origen")]),_c('b-form-group',{attrs:{"label":"Centro Origen"}},[_c('validation-provider',{attrs:{"name":"Centro Origen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.centers,"label":"name","reduce":function (item) { return item.id; },"clearable":false},on:{"input":_vm.changeCenter},model:{value:(_vm.formData.origin_center_id),callback:function ($$v) {_vm.$set(_vm.formData, "origin_center_id", $$v)},expression:"formData.origin_center_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Consultorio Origen"}},[_c('validation-provider',{attrs:{"name":"Consultorio Origen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.originUnits,"label":"name","reduce":function (item) { return item.id; },"clearable":false},on:{"input":_vm.changeUnit},model:{value:(_vm.formData.origin_unit_id),callback:function ($$v) {_vm.$set(_vm.formData, "origin_unit_id", $$v)},expression:"formData.origin_unit_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('h4',[_vm._v("Destino")]),_c('b-form-group',{attrs:{"label":"Centro Destino"}},[_c('validation-provider',{attrs:{"name":"Centro Destino","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.centers,"label":"name","reduce":function (item) { return item.id; },"clearable":false,"disabled":true},model:{value:(_vm.formData.destination_center_id),callback:function ($$v) {_vm.$set(_vm.formData, "destination_center_id", $$v)},expression:"formData.destination_center_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Consultorio Destino"}},[_c('validation-provider',{attrs:{"name":"Consultorio Destino","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.destinationUnits,"label":"name","reduce":function (item) { return item.id; },"clearable":false},model:{value:(_vm.formData.destination_unit_id),callback:function ($$v) {_vm.$set(_vm.formData, "destination_unit_id", $$v)},expression:"formData.destination_unit_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }